<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="dataLoading">
      <v-skeleton-loader type="article, actions"></v-skeleton-loader>
    </v-card>
    <v-stepper v-model="step" v-else class="wraperx" flat outlined>
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
          editable
          :rules="[() => step == 1 || $refs.dispatchForm.validate()]"
        >
          Dispatch Information
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2" editable>
          Checklist
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3" editable> Return Information </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-5 wraperx" flat>
            Dispatch Information
            <v-form ref="dispatchForm">
              <v-row class="mt-8">
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-autocomplete
                    flat
                    v-model="log.vehicle_uuid"
                    :rules="[rules.required]"
                    :items="vehicles"
                    item-text="vehicle_no"
                    item-value="uuid"
                    label="Vehicle No *"
                    outlined
                    readonly
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-text-field
                    flat
                    outlined
                    label="Date *"
                    v-model="log.date"
                    :rules="[rules.required]"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    v-model="menu2"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        flat
                        outlined
                        label="Dispatch Time *"
                        v-model="log.dispatch_time"
                        :rules="[rules.required]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="log.dispatch_time"
                      color="primary"
                      @input="menu2 = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-text-field
                    type="number"
                    flat
                    outlined
                    label="Dispatch Meter (km) *"
                    v-model="log.dispatch_km"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card>

          <v-sheet color="transparent" class="d-flex justify-end">
            <v-btn text @click="goBack"> Cancel </v-btn>

            <v-btn color="primary" @click="step = 2"> Continue </v-btn>
          </v-sheet>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-5 wraperx" flat>
            Checklist

            <v-row class="mt-5 px-2">
              <v-col
                cols="6"
                class="pt-0"
                v-for="(item, i) in checklist"
                :key="i + 'c'"
              >
                <v-checkbox
                  :label="item"
                  :value="item"
                  v-model="log.check_list"
                  color="primary"
                  hide-details
                  dense
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" class="py-0 mt-5">
                <v-textarea
                  flat
                  outlined
                  label="Defects"
                  v-model="log.defects"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>

          <v-sheet color="transparent" class="d-flex justify-end">
            <v-btn text @click="step = 1"> Back </v-btn>
            <v-btn color="primary" @click="step = 3"> Continue </v-btn>
          </v-sheet>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-5 wraperx" flat>
            Return Information
            <v-form ref="returnForm">
              <v-row class="mt-8">
                <v-col cols="12" class="py-0">
                  <v-file-input
                    v-model="image"
                    :rules="[log.image_binary_file != '' || 'required']"
                    label="Image *"
                    outlined
                    accept="image/*"
                    @change="previewImage"
                  ></v-file-input>
                  <v-img
                    v-if="log.image_binary_file"
                    :src="log.image_binary_file"
                    max-height="400"
                    max-width="400"
                    width="100%"
                    contain
                    class="mx-auto mb-8"
                  />
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-text-field
                    type="number"
                    flat
                    outlined
                    label="Return Meter (km) *"
                    v-model="log.return_km"
                    :rules="[rules.required, rules.returnMeterReading]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-text-field
                    flat
                    outlined
                    label="Total km"
                    v-model="log.total_km"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    v-model="menu3"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        flat
                        outlined
                        label="Return Time *"
                        v-model="log.return_time"
                        :rules="[rules.required]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="log.return_time"
                      color="primary"
                      @input="menu3 = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-autocomplete
                    flat
                    v-model="log.project_uuid"
                    :rules="[rules.required]"
                    :items="projects"
                    item-text="code"
                    item-value="uuid"
                    label="Project *"
                    outlined
                    @change="changeProjcet"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-autocomplete
                    flat
                    v-model="log.project_ledger_uuid"
                    :rules="[rules.ledgersR]"
                    :items="ledgers"
                    item-text="code"
                    item-value="uuid"
                    label="Ledger"
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    flat
                    outlined
                    :rules="[rules.required]"
                    label="Description *"
                    v-model="log.description"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
          <v-sheet color="transparent" class="d-flex justify-end">
            <v-btn text @click="step = 2"> Back </v-btn>
            <v-btn
              color="primary"
              @click="editVehicleRunningX"
              :loading="loading"
            >
              Submit
            </v-btn>
          </v-sheet>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { editVehicleRunning } from "@/services/vehicleRunningService";
import { getMyVehicleRunning } from "@/services/myDataService";
import {
  fetchAndStoreVehiclesData,
  fetchAndStoreProjectsData,
} from "@/services/preloadDataService";

import constants from "@/constants.json";
import compress from "compress-base64";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    record: (state) => state.record.data,
  }),
  props: [
    //
  ],
  watch: {
    //
  },
  data: () => ({
    step: 1,
    menu2: false,
    menu3: false,
    menu4: false,
    rules: {},
    loading: false,
    dataLoading: false,

    vehicles: [],
    projects: [],
    drivers: [],
    ledgers: [],
    checklist: constants.vehicleChecklist,
    log: {
      uuid: "",
      driver_uuid: "",
      vehicle_uuid: "",
      project_uuid: "",
      project_ledger_uuid: "",
      date: "",
      dispatch_time: "",
      return_time: "",
      dispatch_km: null,
      return_km: null,
      total_km: null,
      image_binary_file: "",
      image_link: "",
      image_meta: "",
      check_list: [],
      defects: "",
      description: "",
      edit_status: "DISABLED",
      staff: {},
      projects: {},
      project_ledgers: {},
      vehicles: {},
      review_status: "PENDING",
    },
    image: null,
    current_meter_reading: null,
    selectedVehicle: {},
  }),
  async created() {
    this.rules = {
      required: (value) => !!value || "Required.",
      ledgersR: (value) => !!value || this.ledgers.length == 0 || "Required.",
      dispatcMeterReading: (value) => {
        if (!value) return true;
        return (
          Number(value) >= Number(this.current_meter_reading) ||
          `Meter Reading should be greater than or equal to the current value (${this.current_meter_reading}).`
        );
      },
      dispatcMeterReadingOPEnE: (value) => {
        if (!value) return true;
        return (
          (this.selectedVehicle && Number(value) == Number(this.current_meter_reading)) ||
          `Meter Reading should be equal to the current value (${this.current_meter_reading}). Because it's a OPEnE Vehicle.`
        );
      },
      returnMeterReading: (value) => {
        if (!value) return true;
        return (
          Number(value) > Number(this.log.dispatch_km) ||
          `Meter Reading should be greater than the dispatch reading (${this.log.dispatch_km}).`
        );
      },
      pastDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate < today || "The date must be in the past.";
      },
      todayOrFutureDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate >= today || "The date must be today or in the future.";
      },
      futureDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate > today || "The date must be in the future.";
      },
      todayOrPastDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate <= today || "The date must be today or in the past.";
      },
      todayOrYesterday: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        const yesterday = new Date();

        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        yesterday.setDate(today.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);

        return (
          inputDate.getTime() === today.getTime() ||
          inputDate.getTime() === yesterday.getTime() ||
          "The date must be today or yesterday."
        );
      },
    };

    if (
      this.$_checkPermission(
        this.auth.permissions,
        "Manage Vehicle Running Logs",
        "Update"
      )
    ) {
      this.dataLoading = true;
      this.vehicles = await fetchAndStoreVehiclesData();
      this.projects = await fetchAndStoreProjectsData();
      this.log = await getMyVehicleRunning(this.$route.params.uuid);
      if (this.log.driver_uuid != this.auth.uuid) {
        this.goBack();
      }
      this.log.check_list =
        JSON.parse(this.log.check_list) == "[]"
          ? []
          : JSON.parse(this.log.check_list);
      this.changeProjcet();
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "PageViewVehicleRunning",
        params: { uuid: this.log.uuid },
        query: { bc: this.log.id },
      });
    },
    previewImage() {
      if (this.image) {
        let reader = new FileReader();
        reader.readAsDataURL(this.image);
        reader.onload = () => {
          let img = reader.result;
          let type = img.substring(
            "data:image/".length,
            img.indexOf(";base64")
          );
          compress(reader.result, {
            width: 400,
            type: "image/" + type, // default
            max: 200, // max size
            min: 20, // min size
            quality: 0.8,
          }).then((result) => {
            this.log.image_binary_file = result;
            this.log.image_meta = this.image.lastModifiedDate;
          });
        };
      } else {
        this.log.image_binary_file = null;
      }
    },
    async editVehicleRunningX() {
      if (
        this.$refs.returnForm.validate() &&
        this.$refs.dispatchForm.validate()
      ) {
        this.log.projects = this.projects.find(
          (project) => project.uuid == this.log.project_uuid
        );
        this.log.project_ledgers = this.ledgers.find(
          (ledger) => ledger.uuid == this.log.project_ledger_uuid
        );
        this.log.vehicles = this.vehicles.find(
          (vehicle) => vehicle.uuid == this.log.vehicle_uuid
        );
        this.log.check_list = JSON.stringify(this.log.check_list);

        this.log.driver_uuid = this.auth.uuid;

        this.log.staff = this.drivers.find(
          (driver) => driver.uuid == this.log.driver_uuid
        );
        this.log.edit_status = "DISABLED";

        this.loading = true;
        let editVehicleRunningReturn = await editVehicleRunning(
          this.log.vehicle_uuid,
          this.log
        );
        this.loading = false;
        if (editVehicleRunningReturn == "success") {
          this.goBack();
        }
      } else {
        this.$store.commit("setError", {
          isError: true,
          error:
            "Some fields are missing or incorrect. Please check your input.",
        });
      }
    },
    changeProjcet() {
      if (this.log.project_uuid)
        this.ledgers = this.projects.find(
          (obj) => obj.uuid === this.log.project_uuid
        ).project_ledgers;
      else this.ledgers = [];
    },
    changeVehicle() {
      if (this.log.vehicle_uuid) {
        this.selectedVehicle = this.vehicles.find(
          (obj) => obj.uuid === this.log.vehicle_uuid
        );
        this.log.dispatch_km = this.current_meter_reading =
          this.selectedVehicle.current_meter_reading;
      } else {
        this.log.dispatch_km = 0;
        this.selectedVehicle = {};
      }
    },
    changeDriver() {
      if (
        this.vehicles.find(
          (element) => element.driver_uuid == this.log.driver_uuid
        )
      ) {
        this.log.vehicle_uuid = this.vehicles.find(
          (element) => element.driver_uuid == this.log.driver_uuid
        ).uuid;
        this.changeVehicle();
      }
    },
  },
  updated() {
    this.log.total_km = this.$_calculateMeterDiffrence(
      this.log.return_km,
      this.log.dispatch_km
    );
  },
};
</script>